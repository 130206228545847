import type { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { bindRouter } from '@wix/tpa-router/plugins';

import {
  initializeRouter,
  registerApplicationRoutes,
  reloadOnLogin,
} from 'controller/router';
import { addSeoTagsProvider } from 'controller/seo';
import { resolvables } from 'controller/resolvables';
import { initializeStore, persistStore } from 'controller/store';
import { migrateStyles } from 'controller/tpa-styles-migration';
import { initializeSettingsEventHandler } from 'controller/tpa-settings-events';
import { bindViewModel, initializeViewModel } from 'controller/view-model';

import { resolve } from './resolve';
import { seoTagsProvider } from './seo';
import { configureRouter } from './config';

export const controller: CreateControllerFn = function (params) {
  const { flowAPI } = params;
  const router = initializeRouter(params);
  const settingsEvents = initializeSettingsEventHandler(router, params);

  flowAPI.bi?.updateDefaults({ userEntry: 'site' });

  return {
    async pageReady() {
      const store = initializeStore(params);
      const vm = await initializeViewModel(router, store, params);

      configureRouter(router, params);
      reloadOnLogin(router, params);
      registerApplicationRoutes(router, vm, store, params);
      addSeoTagsProvider(router, params, seoTagsProvider(store, params));

      migrateStyles(params);
      bindViewModel(vm, store, params);
      bindRouter(router, params.flowAPI);

      await resolvables(params, resolve(router, params));

      persistStore(store, params);
    },
    updateConfig($w, updatedConfig) {
      settingsEvents.notify(updatedConfig.publicData.COMPONENT || {});
    },
    async onBeforeUnLoad() {
      router.dispose();
    },
  };
};
